import React from 'react'
import { TFunction } from 'i18next'
import { navigate } from 'gatsby'
import Button from '@mui/material/Button'
import Alert from './alert'
import CartIcon from './CartIcon'

interface UpsellingAlertProps {
  t: TFunction
  bestsellerLink: string
}

const UpsellingAlert: React.FC<UpsellingAlertProps> = ({
  t,
  bestsellerLink,
}) => (
  <Alert className="upselling__alert" variant="success">
    <CartIcon className="cart__icon" />
    <span
      className="upselling__alert__text"
      data-test="upselling_alert_success"
    >
      {t('Dein Produkt wurde in den Warenkorb gelegt.')}
    </span>
    <Button
      className="button__contained"
      onClick={() => navigate(bestsellerLink)}
      size="small"
      variant="contained"
    >
      {t('Weiter shoppen')}
    </Button>
    <Button
      onClick={() => navigate('/warenkorb')}
      size="small"
      variant="outlined"
    >
      {t('Zum Warenkorb')}
    </Button>
  </Alert>
)

export default UpsellingAlert
